/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
//  import 'core-js/es6/symbol';
//  import 'core-js/es6/object';
//  import 'core-js/es6/function';
//  import 'core-js/es6/parse-int';
//  import 'core-js/es6/parse-float';
//  import 'core-js/es6/number';
//  import 'core-js/es6/math';
//  import 'core-js/es6/string';
//  import 'core-js/es6/date';
//  import 'core-js/es6/array';
//  import 'core-js/es6/regexp';
//  import 'core-js/es6/map';
//  import 'core-js/es6/weak-map';
//  import 'core-js/es6/set';
//  import 'core-js/es6/array';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es7/reflect';
import 'babel-polyfill';

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

// (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

/*
* in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
* with the following flag, it will bypass `zone.js` patch for IE/Edge
*/
// (window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */
// if (!String.prototype.includes) {
//     String.prototype.includes = function(search, start) {
//       'use strict';
//       if (typeof start !== 'number') {
//         start = 0;
//       }
//       if (start + search.length > this.length) {
//         return false;
//       } else {
//         return this.indexOf(search, start) !== -1;
//       }
//     };
//   }

// if (!String.prototype.startsWith) {
//     String.prototype.startsWith = function(searchString, position) {
//       position = position || 0;
//       return this.indexOf(searchString, position) === position;
//     };
//   }

//   if (!Array.prototype.fill) {
//   Object.defineProperty(Array.prototype, 'fill', {
//     value: function(value) {

//       // Steps 1-2.
//       if (this == null) {
//         throw new TypeError('this is null or not defined');
//       }

//       var O = Object(this);

//       // Steps 3-5.
//       var len = O.length >>> 0;

//       // Steps 6-7.
//       var start = arguments[1];
//       var relativeStart = start >> 0;

//       // Step 8.
//       var k = relativeStart < 0 ?
//         Math.max(len + relativeStart, 0) :
//         Math.min(relativeStart, len);

//       // Steps 9-10.
//       var end = arguments[2];
//       var relativeEnd = end === undefined ?
//         len : end >> 0;

//       // Step 11.
//       var final = relativeEnd < 0 ?
//         Math.max(len + relativeEnd, 0) :
//         Math.min(relativeEnd, len);

//       // Step 12.
//       while (k < final) {
//         O[k] = value;
//         k++;
//       }

//       // Step 13.
//       return O;
//     }
//   });
// }
